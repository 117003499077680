<script setup lang="ts">
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import { Link, usePage } from '@inertiajs/vue3'
import { ProductTypeMenuBlock } from '@interfaces/menu.interface'
import { useI18n } from 'vue-i18n'

const page = usePage()
const { t } = useI18n()

const menu = page.props.layout.menu.navbar.find((menu) => menu.code === 'all')
const productTypes = menu?.blocks.find((block) => block.type === 'product-type') as ProductTypeMenuBlock
</script>

<template>
    <section class="bg-gradient-to-b from-grayscale-50/30 to-white">
        <div class="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
            <div class="mt-1 sm:flex sm:items-baseline sm:justify-between">
                <h1 class="font-semibold text-grayscale-900 text-h4">{{ t('pages.home.featured-categories.title') }}</h1>

                <Link
                    :href="route('catalog')"
                    class="hidden font-medium text-p2 text-primary-600 hover:text-primary-800 sm:inline-flex sm:items-center group"
                >
                {{ t('pages.home.featured-categories.all-categories') }}
                <ArrowRightIcon class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:translate-x-0.5" />
                </Link>
            </div>

            <ul class="grid grid-cols-3 mt-6 sm:grid-cols-4 gap-x-4 gap-y-6 md:grid-cols-5 lg:grid-cols-6 sm:gap-x-6 lg:gap-8">
                <li
                    v-for="(item, itemIdx) in productTypes?.items"
                    :key="itemIdx"
                >
                    <Link
                        :href="item.url"
                        class="flex flex-col h-full group"
                    >
                    <div class="relative mb-2 overflow-hidden rounded-3xl aspect-square ring-1 ring-grayscale-900 ring-opacity-5">
                        <div class="bg-white aspect-square">
                            <img
                                :srcset="`/images/menu/product-types/${item.type}.jpg, /images/menu/product-types/${item.type}@2x.jpg 2x, /images/menu/product-types/${item.type}@3x.jpg 3x`"
                                :src="`/images/menu/product-types/${item.type}.jpg`"
                                width="160"
                                height="160"
                                :alt="item.name"
                                class="object-cover w-full max-w-full transition-transform duration-300 transform group-hover:scale-105"
                                loading="lazy"
                                importance="auto"
                            >
                        </div>
                    </div>

                    <span class="font-normal text-center text-p2 text-grayscale-800 group-hover:text-primary-800">{{ item.name }}</span>
                    </Link>
                </li>
            </ul>

            <div class="mt-6 text-sm md:hidden">
                <Link
                    :href="route('catalog', { _query: { sort: 'newest' } })"
                    class="inline-flex items-center font-medium text-primary-600 hover:text-primary-800"
                >
                {{ t('pages.home.featured-categories.all-categories') }}
                <ArrowRightIcon class="w-4 h-4 ml-1" />
                </Link>
            </div>
        </div>
    </section>
</template>
